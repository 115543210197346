@use '../../styles/config/breakpoints';
@use '../../styles/config/typography';
@use '../../styles/config/colors';

.cardWrapper {
  gap: 25px;
  display: flex;
  flex-direction: column;
  background: colors.$white;
  border-radius: 5px;
  margin-bottom: 25px;
  padding: 25px;
  box-shadow: 0px 6px 8px rgba(5, 31, 44, 0.16), 0px 2px 12px rgba(5, 31, 44, 0.16),
    0px 2px 4px rgba(5, 31, 44, 0.16);
}

.subheader {
  padding-left: 15px;
  font-weight: typography.$font-weight-bold;
  font-size: typography.$font-size-medium;
}

.headerWithLogo {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: typography.$font-size-large;

  span {
    padding-left: typography.$font-size-base;
    font-style: normal;
    font-weight: typography.$font-weight-bold;
    font-size: typography.$font-size-x-large;
    color: colors.$black;
  }

  @include breakpoints.sm {
    margin-top: 50px;
  }
}

.button {
  align-items: center;
  text-align: center;
  color: colors.$white;
  box-sizing: border-box;
  cursor: pointer;
  background: colors.$blue-100;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  max-width: 171px;
  height: 36px;
  border: none;

  span {
    font-style: normal;
    font-weight: typography.$font-weight-bold;
    font-size: typography.$font-size-small;
    text-align: center;

    color: colors.$white;
    align-items: center;
    float: none !important;
  }
}

.articlesLink {
  font-weight: typography.$font-weight-normal;
  font-size: typography.$font-size-small;
  text-decoration-line: underline;
  color: colors.$blue-100;
}

.articlesWrapper {
  padding-left: 15px;
}

.articleRow {
  min-height: typography.$font-size-xx-large;
  list-style-type: none;
  color: colors.$blue-100;
}

.articleRow a {
  text-decoration-line: underline;
  color: colors.$blue-100;
  cursor: pointer;
}

.header {
  font-style: normal;
  font-weight: typography.$font-weight-bold;
  font-size: typography.$font-size-medium;
  color: colors.$dark-blue;
}

.descriptionParagraph {
  font-weight: typography.$font-weight-normal;
  font-size: typography.$font-size-medium;
  color: colors.$dark-blue;
  margin-bottom: 12px;
}

.faq {
  margin-bottom: 50px;
}

.faqDetails {
  flex-direction: column;
}
