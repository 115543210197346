@use '../../styles/config/typography';
@use '../../styles/config/colors';

.wrapper {
  width: 250px;
  display: flex;
  flex-direction: column;
  padding-right: 15px;
}

.image {
  max-width: 100%;
  height: auto;
}

.title {
  font-style: normal;
  font-weight: typography.$font-weight-bold;
  font-size: typography.$font-size-large;
  line-height: 24px;
  color: colors.$black;
}
