@use '../../styles/config/typography';
@use '../../styles/config/colors';
@use '../../styles/config/breakpoints';

.formWrapper {
  padding-right: 5%;
  padding-left: 5%;
}

.modalBody {
  display: flex;
  flex-direction: column;

  .header {
    margin-top: 25px;
    margin-bottom: 25px;
    text-align: center;
  }

  .progress {
    margin-top: 25px;
    width: 100%;
  }

  .topDescription {
    max-width: 483px;
    margin: 25px auto;

    font-style: normal;
    font-weight: typography.$font-weight-normal;
    font-size: typography.$font-size-base;
    line-height: typography.$font-size-x-large;
    color: colors.$dark-blue;
  }

  .sampleImageTitle {
    text-align: center;
  }

  .sampleImageFooter {
    font-style: italic;
    text-align: center;
  }

  .sampleImage {
    width: 100%;
    border-radius: 0;
  }
}

form {
  .formControl {
    width: 100%;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;

    @include breakpoints.md {
      width: 90%;
    }

    label {
      display: contents;
      font-style: normal;
      font-weight: typography.$font-weight-normal;
      font-size: typography.$font-size-base;
      line-height: typography.$font-size-x-large;
      color: colors.$dark-blue;
    }
    &:before,
    &:after {
      content: none;
    }

    input {
      margin-top: 4px;
      margin-bottom: 30px;
      background: colors.$white;
      border: 1px solid colors.$blue-grey;
      border-radius: 5px;
      height: 44px;
      padding-left: 15px;
      font-style: normal;
      font-weight: typography.$font-weight-normal;
      font-size: 18px;
      line-height: 23px;
      color: colors.$blue-grey;
    }
  }
  .formConsolidateError {
    font-style: normal;
    font-weight: typography.$font-weight-normal;
    font-size: typography.$font-size-base;
    line-height: typography.$font-size-x-large;
    color: colors.$red-100;
  }
}

.modalFormButton {
  background: colors.$blue-100;
  border: none;
  border-radius: 100px;
  margin-top: 20px;
  margin-bottom: 50px;
  padding: 10px;
  width: 100%;

  @include breakpoints.md {
    width: 90%;
  }

  span {
    font-style: normal;
    font-weight: typography.$font-weight-bold;
    font-size: typography.$font-size-base;
    line-height: 22px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: colors.$white;
  }
  &:hover {
    cursor: pointer;
  }
}
