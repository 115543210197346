@use '../../../styles/config/colors';

.card {
  display: flex;
  flex-direction: column;
  background: colors.$white;
  border-radius: 5px;
  margin-bottom: 50px;
  padding: 25px;
  box-shadow: 0px 6px 8px rgba(5, 31, 44, 0.16), 0px 2px 12px rgba(5, 31, 44, 0.16),
    0px 2px 4px rgba(5, 31, 44, 0.16);
}

.subheader {
  padding-left: 15px;
}

.notice {
  padding: 2%;
  background-color: colors.$blue-25;
  border: 1px solid colors.$blue-100;
  border-radius: 5px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
}

.infoIcon {
  color: colors.$blue-100;
}

.noticeText {
  padding-left: 15px;
}
