@use '../../styles/config/typography';
@use '../../styles/config/colors';
@use '../../styles/config/breakpoints';

.card {
  display: flex;
  flex-direction: column;
  background: colors.$white;
  border-radius: 5px;
  margin-bottom: 50px;
  padding: 25px 25px 10px;
  box-shadow: 0px 6px 8px rgba(5, 31, 44, 0.16), 0px 2px 12px rgba(5, 31, 44, 0.16),
    0px 2px 4px rgba(5, 31, 44, 0.16);
}

.header {
  margin: 25px 10px;

  @include breakpoints.md {
    margin: 40px 0;
  }
}

.subheader {
  padding-left: 15px;
}

.contactTab {
  margin-top: 20px;
  margin-bottom: 20px;
}

.contactTabElement {
  font-weight: typography.$font-weight-bold;
  border-bottom: 3px solid colors.$blue-100;
  display: inline;
}

.contactTabElementLast {
  border-bottom: 1px solid colors.$gray-50;
}

.iframe {
  border: none;
  width: 100%;
  height: 655px;

  // compensate form's padding on large screens
  @include breakpoints.md {
    margin-top: -25px;
    margin-left: -25px;
  }
}
