@use '../../styles/config/typography';
@use '../../styles/config/colors';

.headerWrapper {
  display: flex;
  flex-direction: row;
  align-content: center;
  margin-bottom: 10px;
}

.headerActionRequired {
  text-align: center;
  background: colors.$yellow-25;
  border: 1px solid colors.$yellow-100;
  border-radius: 3px;
  padding: 0 5px;
  span {
    font-style: normal;
    font-weight: typography.$font-weight-bold;
    font-size: typography.$font-size-tiny;
    line-height: typography.$font-size-x-large;
    text-align: center;
    letter-spacing: 0.05em;
    color: colors.$dark-blue;
  }
}

.headerText {
  align-self: center;
  font-style: normal;
  padding-right: 15px;
  font-weight: typography.$font-weight-bold;
  font-size: typography.$font-size-base + 2;
  line-height: typography.$font-size-x-large;
  color: colors.$dark-blue;
  margin-bottom: 10px;
}

.description {
  font-style: normal;
  font-weight: typography.$font-weight-normal;
  font-size: typography.$font-size-base;
  line-height: typography.$font-size-x-large;
  color: colors.$dark-blue;
}
