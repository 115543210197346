@use '../../styles/config/breakpoints';
@use '../../styles/config/colors';
@use '../../styles/config/typography';

.headerWithoutLogo {
  display: flex;
  align-items: center;
  font-weight: typography.$font-weight-bold;
  font-size: typography.$font-size-x-large;
  color: colors.$black;
  width: 100%;
  line-height: 36px;
  height: 36px;
  margin: 12px 0;

  @include breakpoints.sm {
    margin-top: 50px;
  }
}

.articlesWrapper {
  display: flex;
  row-gap: 25px;
  flex-wrap: wrap;
  margin-top: 18px;
}
