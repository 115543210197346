$black: #000000;
$white: #ffffff;

$blue-100: #007cc2;
$dark-blue: #051f2c;
$blue-grey: #637582;
$gray-50: #dee5ea;
$blue-25: #f0faff;

$grey: #d9d9d9;
$dark-grey: #333333;
$light-grey: #f2f5f7;
$gray-60: #a3b5c2;

$green: #98cf69;
$green-100: #508128;
$dark-green: #16855e;
$light-green: #eff7e9;

$red-100: #d9362c;
$yellow-25: #fff4e3;
$yellow-100: #936d0b;

:export {
  black: $black;
  white: $white;
  blue-100: $blue-100;
  dark-blue: $dark-blue;
  blue-25: $blue-25;
  light-grey: $grey;
  green: $green;
  green-100: $green-100;
  light-green: $light-green;
  dark-green: $dark-green;
  red-100: $red-100;
  yellow-25: 25;
  yellow-100: $yellow-100;
}
