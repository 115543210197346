@use '../../styles/config/colors';
@use '../../styles/config/breakpoints';
@use '../../styles/config/typography';

.header {
  font-weight: typography.$font-weight-bold;
  font-size: typography.$font-size-x-large;
  margin: 15px 10px 25px;

  @include breakpoints.md {
    margin: 0 0 25px;
  }
}

.cardsRowWrapper {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(2, 1fr);

  @include breakpoints.sm {
    grid-template-columns: repeat(3, 1fr);
  }
}
