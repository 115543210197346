@use '../../styles/config/typography';
@use '../../styles/config/colors';
@use '../../styles/config/breakpoints';

.horizontalBreakLine {
  border: 1px solid colors.$gray-50;
  width: 100%;
}

.buttonsWrapper {
  display: flex;
  column-gap: 15px;
}

.button {
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-align: center;
  color: colors.$white;
  background: colors.$blue-100;
  border-radius: 100px;
  justify-content: center;
  width: 100%;
  height: 36px;
  border: none;

  @include breakpoints.sm {
    max-width: 171px;
  }

  span {
    font-style: normal;
    font-weight: typography.$font-weight-bold;
    font-size: typography.$font-size-small;
    line-height: 19px;
    text-align: center;

    color: colors.$white;
    align-items: center;
    float: none !important;
  }
}

.buttonNotFilled {
  box-sizing: border-box;
  height: 36px;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: colors.$white;
  border: 1px solid colors.$blue-100;
  border-radius: 100px;
  background: colors.$white;
  width: 100%;

  @include breakpoints.sm {
    max-width: 171px;
  }

  span {
    font-style: normal;
    font-weight: typography.$font-weight-bold;
    font-size: typography.$font-size-small;
    line-height: 19px;
    text-align: center;
    color: colors.$blue-100;
  }
}
