@use 'styles/config/typography';
@use 'styles/config/colors';

.memberPortalContainer {
  background: colors.$white;
  font-family: typography.$font-family;
  font-size: typography.$font-size-base;
  font-weight: typography.$font-weight-normal;
  line-height: 24px;
  color: colors.$dark-grey;

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  a {
    text-decoration: none;
  }

  ul,
  li {
    list-style-type: none;
  }

  h1 {
    font-weight: typography.$font-weight-bold;
    font-size: typography.$font-size-x-large;
  }

  h2 {
    font-weight: typography.$font-weight-bold;
    font-size: typography.$font-size-medium;
  }
}

.body {
  display: flex;
  background: colors.$light-grey;
  min-height: 100vh;
}

.main {
  flex-basis: 100%;
}

.menuBody {
  border-color: colors.$gray-50;
  a {
    color: colors.$black;
  }
}

.nav > li > a:hover,
.nav .open > a {
  background-color: colors.$white;
}

.horizontalBreakLine {
  border: 1px solid colors.$gray-50;
  width: 100%;
}

.whiteText {
  display: flex;
  align-items: center;
  text-align: center;
  color: colors.$white;
}

.button {
  box-sizing: border-box;
  border-radius: 90px;
  width: 171px;
  height: 36px;
  cursor: pointer;
}

.buttonBlue {
  background: colors.$blue-100;
  border: 1px solid colors.$blue-100;
  color: colors.$white;
}

.capitalizeText {
  text-transform: capitalize;
}
