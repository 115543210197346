@use '../../../styles/config/colors';

.card {
  display: flex;
  flex-direction: column;
  background: colors.$white;
  border-radius: 5px;
  margin-bottom: 50px;
  padding: 25px;
  box-shadow: 0px 6px 8px rgba(5, 31, 44, 0.16), 0px 2px 12px rgba(5, 31, 44, 0.16),
    0px 2px 4px rgba(5, 31, 44, 0.16);
}

.subheader {
  padding-left: 15px;
}

.disabled {
  pointer-events: none;
}

.divider {
  width: 100%;
}

.changePasswordText {
  padding-top: 17px;
  color: colors.$blue-100;
  text-decoration-line: underline;
  font-size: 16px;
}
