@use '../../styles/config/typography';
@use '../../styles/config/colors';
@use '../../styles/config/breakpoints';

.wrapper {
  margin-top: 45px;
  padding: 0;
}

.headerNav,
.childNav {
  font-size: typography.$font-size-base;
  font-style: normal;
  padding: 0 24px;
  height: 42px;
  line-height: 30px;
  color: colors.$black;
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-width: 150px;
}

.headerNav {
  font-weight: typography.$font-weight-bold;
  font-size: typography.$font-size-tiny;
  line-height: 18px;
  text-transform: uppercase;
  color: colors.$blue-grey;
}

.list {
  margin: 0;
  padding: 0;
}

.navWithIcon {
  display: flex;
  align-items: center;

  img {
    height: 40px;
    width: 35px;
    padding-right: 10px;
  }
}

.link {
  font-size: typography.$font-size-base;
  font-style: normal;
  padding: 0 24px;
  height: 42px;
  line-height: 30px;
  color: colors.$black;
  display: flex;
  justify-content: left;
  min-width: 150px;
  align-items: center;
  font-weight: typography.$font-weight-normal;
}

.link.activeLink {
  background: colors.$light-grey;
  font-weight: typography.$font-weight-bold;
}
