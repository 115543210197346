@use '../../styles/config/colors';
@use '../../styles/config/breakpoints';
@use '../../styles/config/typography';

$side-bar-width: 240px;

.wrapper {
  padding-right: 0;
  background: colors.$white;
  width: $side-bar-width;
  flex-shrink: 0;
  font-family: typography.$font-family;
  font-size: typography.$font-size-base;
  font-weight: typography.$font-weight-normal;
  line-height: 24px;
  color: colors.$dark-grey;

  @include breakpoints.sm {
    padding-top: 36px;
    min-width: auto;
  }

  a {
    text-decoration: none;
  }

  ul,
  li {
    padding-top: 0;
    padding-bottom: 0;
  }
}
