@use '../../styles/config/typography';
@use '../../styles/config/colors';

.remainingDaysBox {
  background: colors.$light-green;
  border: 1px solid colors.$green;
  border-radius: 4px;
  font-style: normal;
  font-weight: typography.$font-weight-normal;
  font-size: typography.$font-size-base;
  line-height: 22px;
  color: colors.$black;
  padding: 10px;
  margin-bottom: 10px;
}
