$font-family: 'Open Sans', sans-serif;

$font-size-xx-large: 36px;
$font-size-x-large: 24px;
$font-size-large: 21px;
$font-size-medium: 18px;
$font-size-base: 16px;
$font-size-small: 14px;
$font-size-tiny: 12px;

$font-weight-normal: 400;
$font-weight-bold: 700;

:export {
  font-family: $font-family;

  font-size-x-large: $font-size-x-large;
  font-size-large: $font-size-large;
  font-size-medium: $font-size-medium;
  font-size-base: $font-size-base;
  font-size-small: $font-size-small;
  font-size-tiny: $font-size-tiny;

  font-weight-normal: $font-weight-normal;
  font-weight-bold: $font-weight-bold;
}
