@use '../../styles/config/typography';
@use '../../styles/config/colors';
@use '../../styles/config/breakpoints';

.greetingWrapper {
  background: colors.$blue-100;
  align-items: center;
  display: flex;

  @include breakpoints.sm {
    height: 174px;
  }
}

.greeting {
  padding: 10px 10px 10px 20px;
  font-style: normal;
  font-weight: typography.$font-weight-normal;
  font-size: typography.$font-size-xx-large;
  line-height: 54px;
  color: colors.$white;

  @include breakpoints.sm {
    padding: 20px 20px 20px 55px;
  }
}
