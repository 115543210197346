@use '../../styles/config/typography';
@use '../../styles/config/colors';
@use '../../styles/config/breakpoints';

.policyCardWrapper {
  display: flex;
  flex-direction: column;
  row-gap: 5px;

  .title {
    font-style: normal;
    font-weight: typography.$font-weight-bold;
    font-size: 18px;
    line-height: 25px;
    color: colors.$black;
  }

  .quoteInfo {
    display: flex;
    justify-content: space-between;
    height: 36px;
    flex: 1;
    row-gap: 20px;
    flex-wrap: wrap;
    align-items: center;

    span {
      font-style: normal;
      font-weight: typography.$font-weight-normal;
      font-size: typography.$font-size-base;
      line-height: 24px;
      color: colors.$dark-blue;
    }

    .carrierAndPolicy {
      width: 100%;
      display: flex;
      flex-direction: column;
      row-gap: 10px;
      img {
        max-width: 154px;
        max-height: 57px;
      }
      span {
        font-weight: typography.$font-weight-normal;
        font-size: typography.$font-size-base;
        line-height: 24px;
        color: colors.$black;
      }

      @include breakpoints.md {
        width: 67%;
      }
    }

    .displayLogoText {
      font-weight: typography.$font-weight-bold;
      font-size: typography.$font-size-medium + 2;
      line-height: typography.$font-size-xx-large - 6;
      color: colors.$black;
    }

    .buttonNotFilled {
      border: 1px solid colors.$blue-100;
      border-radius: 100px;
      background: colors.$white;
      width: 171px;
      height: 36px;
      cursor: pointer;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      background: colors.$white;
      @include breakpoints.sm {
        max-width: 171px;
      }
      span {
        font-style: normal;
        font-weight: typography.$font-weight-bold;
        font-size: typography.$font-size-small;
        line-height: 19px;
        text-align: center;
        color: colors.$blue-100;
        align-items: center;
        float: none !important;
      }
    }
  }

  .ctas {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    flex: 0;
    .askQuestion {
      padding-left: 40px;
      span {
        font-weight: 700;
        font-size: 14px;
        line-height: 19px;
        text-align: center;
        color: colors.$blue-100;
      }
      &:hover {
        cursor: pointer;
      }
    }

    @include breakpoints.md {
      width: 33%;
    }
  }
}
