@use '../../styles/config/typography';
@use '../../styles/config/colors';
@use '../../styles/config/breakpoints';

.title {
  font-style: normal;
  font-weight: typography.$font-weight-bold;
  font-size: 18px;
  line-height: 25px;
  color: colors.$black;
}

.subTitle {
  font-style: normal;
  font-weight: typography.$font-weight-normal;
  font-size: typography.$font-size-base;
  line-height: 24px;
  color: colors.$dark-blue;
}

.quoteInfo {
  display: flex;
  justify-content: space-between;
  height: 36px;
  flex: 1;
  row-gap: 20px;
  flex-wrap: wrap;
  align-items: center;

  span {
    font-style: normal;
    font-weight: typography.$font-weight-normal;
    font-size: typography.$font-size-base;
    line-height: 24px;
    color: colors.$dark-blue;
  }
}

.needHelp {
  font-style: normal;
  font-weight: typography.$font-weight-normal;
  font-size: typography.$font-size-base;
  line-height: 22px;
  color: colors.$black;

  span {
    color: colors.$dark-blue;
  }
}

.alreadyPurchased {
  font-style: normal;
  font-weight: typography.$font-weight-normal;
  font-size: typography.$font-size-base;
  line-height: 24px;
  color: colors.$dark-blue;

  button {
    cursor: pointer;
    color: colors.$blue-100;
  }
}

.clickHereButton {
  span {
    text-transform: none;
    text-decoration: underline;
  }
}

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 25px;
  gap: 25px;
  background: colors.$white;
  /* Shadow/Medium */

  box-shadow: 0px 6px 8px rgba(5, 31, 44, 0.16), 0px 2px 12px rgba(5, 31, 44, 0.16),
    0px 2px 4px rgba(5, 31, 44, 0.16);
  margin-bottom: 20px;

  @include breakpoints.xs-only {
    hr {
      margin: 0;
    }
  }
}

.progressWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-right: -15px;
}

.buttonNotFilled {
  border: 1px solid colors.$blue-100;
  border-radius: 100px;
  background: colors.$white;
  width: 100%;

  @include breakpoints.sm {
    max-width: 171px;
  }

  span {
    font-style: normal;
    font-weight: typography.$font-weight-bold;
    font-size: typography.$font-size-small;
    line-height: 19px;
    text-align: center;
    color: colors.$blue-100;
  }
}

.button {
  background: colors.$blue-100;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 36px;
  border: none;

  @include breakpoints.sm {
    max-width: 171px;
  }

  span {
    font-style: normal;
    font-weight: typography.$font-weight-bold;
    font-size: typography.$font-size-small;
    line-height: 19px;
    text-align: center;

    color: colors.$white;
    align-items: center;
    float: none !important;
  }
}

.viewPlanButton {
  background: colors.$blue-100;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  height: 36px;
  border: none;

  span {
    font-style: normal;
    font-weight: typography.$font-weight-bold;
    font-size: typography.$font-size-small;
    line-height: 19px;
    text-align: center;

    color: colors.$white;
    align-items: center;
    float: none !important;
  }
}

.helpLinks,
.helpLinks a {
  font-weight: typography.$font-weight-normal;
  font-size: typography.$font-size-base;
  line-height: 24px;
  text-decoration-line: underline;
  color: colors.$blue-100;
}
