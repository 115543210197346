@use '../../styles/config/typography';
@use '../../styles/config/colors';

.topTitle {
  font-size: typography.$font-size-base;
  font-style: normal;
  font-weight: typography.$font-weight-normal;
  line-height: 36px;
  color: colors.$black;
}

.loiWrapper {
  padding-left: 15px;
}

.loiRow {
  list-style-type: disc;
  line-height: 30px;
  height: 35px;
  color: colors.$blue-100;
}

.loiRow a {
  text-decoration-line: underline;
  color: colors.$blue-100;
  cursor: pointer;
}
