@use '../../../styles/config/typography';
@use '../../../styles/config/colors';

.container {
  background: colors.$white;
  padding-top: 8px;
  border-radius: 8px;
}

.inputFile {
  display: none;
}

.browseButton,
.mobileUploadButton {
  /* blue-100 */
  background: colors.$blue-100;
  border-radius: 100px;
  padding: 9px typography.$font-size-base - 4;
  text-align: center;

  span {
    font-style: normal;
    font-weight: typography.$font-weight-bold;
    font-size: typography.$font-size-small;
    line-height: typography.$font-size-base + 3;
    text-align: center;

    color: #ffffff;
  }
}

.browseButton {
  width: 132px;
}

.mobileUploadButton {
  margin-top: 17px;
  margin-bottom: 17px;
  width: 182px;
}

.desktopContent {
  display: none;
}

.mobileContent {
  display: none;
}

@media (pointer: fine) {
  div.desktopContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media (pointer: coarse) {
  div.mobileContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.labelUpload {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: colors.$light-grey;
  border: 1px dashed colors.$black;
  padding: 1.5rem;
  cursor: pointer;

  h1 {
    padding-top: 10px;
    padding-bottom: 8px;
    text-align: center;
    font-style: normal;
    font-weight: typography.$font-weight-normal;
    font-size: typography.$font-size-base - 1;
    color: colors.$black;
    margin: 0;
  }

  h1.noTopPadding {
    padding-top: 0;
  }

  h1.fileValidationInfo {
    padding-top: 8px;
    padding-bottom: 0;
  }

  h1.yellowgreen {
    color: yellowgreen;
  }

  h1.noMargin {
    margin: 0;
  }
}

.fileDropError {
  width: 100%;
  color: red;
  display: inline-block;
  margin-bottom: 1rem;
}

.fileProperties {
  margin-top: 1rem;
  ul {
    list-style: none;
    text-align: center;
  }
}
