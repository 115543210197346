@use '../../styles/config/typography';
@use '../../styles/config/colors';
@use '../../styles/config/breakpoints';

.modalBox {
  position: absolute;
  max-width: 750px;
  min-height: 400px;
  max-height: 600px;
  overflow-y: auto;
  min-width: 300px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: colors.$white;
}

.modalHeader {
  height: 64px;
  border-bottom: 1px solid colors.$gray-60;
  display: flex;
  justify-content: center;
  align-items: center;

  h2 {
    font-style: normal;
    font-weight: typography.$font-weight-bold;
    font-size: typography.$font-size-medium;
    line-height: typography.$font-size-xx-large;
    text-align: center;
    color: colors.$black;
    position: relative;

    @include breakpoints.sm {
      font-size: typography.$font-size-x-large;
    }
  }
  .close {
    right: 0;
    position: absolute;
    color: colors.$black;
  }
}
