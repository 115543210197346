@use '../../styles/config/typography';
@use '../../styles/config/colors';
@use '../../styles/config/breakpoints';

.root {
  margin: 0;
  padding: 0;
}

.sideNavHeaderHighlighted,
.sideNavChildren {
  font-size: typography.$font-size-base;
  font-style: normal;
  padding: 0 24px;
  height: 42px;
  line-height: 30px;
  color: colors.$black;
  display: flex;
  justify-content: left;
  min-width: 150px;
  align-items: center;

  @include breakpoints.md {
    padding: 0 24px;
  }
}

.sideNavHeaderHighlighted {
  background: colors.$light-grey;
  font-weight: typography.$font-weight-bold;
}

.sideNavChildren {
  font-weight: typography.$font-weight-normal;
}
