@use '../../../styles/config/typography';
@use '../../../styles/config/colors';

.header {
  font-style: normal;
  font-weight: typography.$font-weight-bold;
  font-size: typography.$font-size-base + 2;
  line-height: typography.$font-size-x-large;
  color: colors.$dark-blue;
}

.description {
  font-style: normal;
  font-weight: typography.$font-weight-normal;
  font-size: typography.$font-size-base;
  line-height: typography.$font-size-x-large;
  color: colors.$dark-blue;
}
