@use '../../styles/config/typography';
@use '../../styles/config/colors';

.wrapper {
  display: flex;
  flex-direction: column;
  flex: 1 1 0px;
  position: relative;
  align-items: center;
}

.boldTitle,
.title {
  font-style: normal;
  font-size: typography.$font-size-small;
  line-height: 30px;
  color: colors.$black;
}

.title {
  font-weight: typography.$font-weight-normal;
}

.boldTitle {
  font-weight: typography.$font-weight-bold;
}

.circleWithProgress {
  display: flex;
}

.greenCircle,
.whiteCircle {
  width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 50%;
  font-size: 15px;
  text-align: center;
  z-index: 55;
  align-items: center;
  justify-content: center;
  display: flex;
}

.whiteCircle {
  color: colors.$blue-grey;
  background: colors.$white;
  border: 1px solid colors.$blue-grey;
}

.greenCircle {
  background: colors.$green-100;
  color: colors.$white;
}

.bar {
  background: colors.$white;
  border: 1px solid #dee5ea;
  display: flex;
  width: 100%;
  height: 0px;
  border-left: none;
  border-right: none;
  border-radius: 0;
  align-self: center;
  z-index: 50;
  position: absolute;
}
