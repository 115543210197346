@use '../../styles/config/breakpoints';
@use '../../styles/config/typography';
@use '../../styles/config/colors';

.card {
  display: flex;
  flex-direction: column;
  background: colors.$white;
  border-radius: 5px;
  margin-bottom: 50px;
  padding: 25px 25px 10px;
  box-shadow: 0px 6px 8px rgba(5, 31, 44, 0.16), 0px 2px 12px rgba(5, 31, 44, 0.16),
    0px 2px 4px rgba(5, 31, 44, 0.16);
}

.header {
  margin: 25px 10px;

  @include breakpoints.md {
    margin: 40px 0;
  }
}

.subheader {
  padding-left: 15px;
}

.dialogTitle {
  font-weight: typography.$font-weight-bold;
  font-size: typography.$font-size-x-large;
}

.closeIcon {
  float: right;
}

.dialogForm {
  padding-top: 5%;
  padding-bottom: 10%;
}
