@use '../../styles/config/typography';
@use '../../styles/config/colors';

.headerWithLogo {
  display: flex;
  align-items: center;
  width: 100%;
  line-height: 36px;
  height: 36px;
  margin-bottom: 12px;

  img {
    padding-right: 16px;
  }

  span {
    font-style: normal;
    font-weight: typography.$font-weight-bold;
    font-size: typography.$font-size-x-large;
    color: colors.$black;
  }
}
