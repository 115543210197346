@use '../../../styles/config/typography';
@use '../../../styles/config/colors';

.wrapper {
  padding-left: 10px;
  border-left: 5px solid colors.$gray-50;
  margin-bottom: 10px;
}

p.headerText {
  font-size: typography.$font-size-base;
  font-weight: typography.$font-weight-bold;
  line-height: typography.$font-size-x-large;
  color: colors.$dark-blue;
  padding-right: 5px;
  padding-bottom: 10px;
}

.paragraphText {
  padding-bottom: typography.$font-size-tiny - 2;
  font-style: normal;
  font-weight: typography.$font-weight-normal;
  font-size: typography.$font-size-small;
  line-height: typography.$font-size-large;
  color: colors.$dark-blue;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0;
}

.rejectMessage {
  background: colors.$yellow-25;
  border: 1px solid colors.$yellow-100;
  border-radius: 3px;
  padding: 10px;
  margin-bottom: 10px;
  font-family: typography.$font-family;
  font-size: typography.$font-size-small;

  span {
    color: colors.$dark-blue;
  }
}

.viewMoreLink {
  background: none !important;
  border: none;
  padding: 0 5px 0 0 !important;
  color: colors.$blue-100;
  cursor: pointer;
}

.listItemText {
  margin: 0;
  padding-left: 15px;
  list-style-type: disc;
  li {
    list-style-type: disc;
    font-style: normal;
    font-weight: typography.$font-weight-normal;
    font-size: typography.$font-size-small;
    line-height: typography.$font-size-large;
    color: colors.$dark-blue;
    flex: none;
    order: 1;
    flex-grow: 0;
  }
}
