@use '../../../styles/config/breakpoints';

.root {
  justify-content: center;
  padding: 10px;
  min-height: 70vh;

  @include breakpoints.md {
    padding: 55px 10px;
  }
}
