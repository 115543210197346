@use '../../../styles/config/typography';
@use '../../../styles/config/colors';

.wrapper {
  margin-bottom: 10px;
}

.headerText {
  font-size: typography.$font-size-base;
  color: colors.$dark-blue;
  padding-right: 5px;
}

.collapseExpandText {
  display: flex;
  font-style: normal;
  font-weight: typography.$font-weight-normal;
  font-size: typography.$font-size-base;
  line-height: typography.$font-size-x-large;
  text-decoration-line: underline;
  color: colors.$blue-100;
  cursor: pointer;
}

.bodyWrapperCollapsed {
  font-style: normal;
  font-weight: typography.$font-weight-normal;
  font-size: typography.$font-size-small;
  line-height: typography.$font-size-large;
  color: colors.$blue-grey;
}

.headerWrapper {
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
}

.leftLineWrapper {
  padding-left: 10px;
  border-left: 5px solid colors.$gray-50;
}

.collapsedHeaderText {
  font-style: normal;
  font-weight: typography.$font-weight-normal;
  font-size: typography.$font-size-base;
  line-height: typography.$font-size-x-large;
  color: colors.$dark-blue;
}

.bodyWrapper {
  background: colors.$blue-25;
  border: 1px solid colors.$blue-100;
  border-radius: 3px;
  padding: 10px;
  font-family: typography.$font-family;
  font-weight: typography.$font-weight-normal;
  font-size: typography.$font-size-small;

  span {
    color: colors.$dark-blue;
  }
}

.viewMoreLink {
  font-size: inherit;
  background: none !important;
  border: none;
  padding: 0 5px 0 0 !important;
  color: colors.$blue-100;
  cursor: pointer;
}
