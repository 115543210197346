@use '../../../styles/config/colors';
@use '../../../styles/config/breakpoints';

.main {
  padding: 10px;
}

.breadCrumbs {
  padding-bottom: 25px;
}

.breadCrumbsRoot {
  color: colors.$blue-100;
  text-decoration-line: underline;
}

.faqWrapper {
  display: block;

  @include breakpoints.sm {
    display: flex;
  }
}

.faqSideBar {
  display: flex;
  flex-direction: column;
  min-width: auto;

  @include breakpoints.sm {
    width: 20%;
    margin-right: 10px;
  }
}

.accordion {
  display: flex;
  flex-direction: column;
  min-width: auto;

  @include breakpoints.sm {
    width: 80%;
  }
}

.selectedSideBarTopic {
  background: colors.$gray-50;
  border-left: 4px solid colors.$blue-100;
  padding: 5px;

  &:hover {
    cursor: pointer;
  }
}

.sideBarTopic {
  &:hover {
    cursor: pointer;
  }
}

.accordionHeader {
  margin-bottom: 25px;
}
