@use '../../styles/config/typography';
@use '../../styles/config/colors';
@use '../../styles/config/breakpoints';

.footer {
  width: 100%;
  background: colors.$dark-blue;
  color: colors.$white;
  font-size: typography.$font-size-base;
  font-weight: typography.$font-weight-normal;
  padding: 41px 15px;

  @include breakpoints.md {
    padding: 41px 163px;
  }
}
