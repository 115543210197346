@use '../../styles/config/breakpoints';
@use '../../styles/config/typography';
@use '../../styles/config/colors';

.header {
  margin: 25px 10px;

  @include breakpoints.md {
    margin: 40px 0;
  }
}

.dialogTitle {
  font-weight: typography.$font-weight-bold;
  font-size: typography.$font-size-x-large;
}

.closeIcon {
  float: right;
}

.dialogForm {
  padding-top: 5%;
  padding-bottom: 10%;
}

.bold {
  font-weight: typography.$font-weight-bold;
}

.errorText {
  color: colors.$red-100;
  text-align: center;
}

.conditionSatisfied {
  color: colors.$dark-green;
}

.preferenceWrapper {
  width: 100%;
}

.preferenceOption {
  border: 1px solid colors.$gray-50;
  border-radius: 5px;
  box-sizing: border-box;
  margin-bottom: 10px;
}
