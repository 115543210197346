.contactButton {
  border-radius: 100;
  background-color: #007cc2;
}

.contactUs {
  padding-top: 55px;

  p {
    padding: 10px;
  }

  a {
    min-width: 210px;
  }
}
