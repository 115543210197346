@use '../../../styles/config/typography';
@use '../../../styles/config/breakpoints';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 16px 5px;
  min-height: 180px;
  text-align: center;

  img {
    height: 48px;
    width: 48px;
  }
}

.header {
  font-weight: typography.$font-weight-bold;

  @include breakpoints.sm {
    font-size: typography.$font-size-medium;
    padding-bottom: 8px;
  }
}

.text {
  display: none;

  @include breakpoints.sm {
    display: block;
  }
}
