@use '../../styles/config/colors';

.navBar {
  width: 100%;
  justify-content: space-between;
  display: flex;
  padding: 10px;
  border-bottom: colors.$gray-50 solid 1px;
  align-items: center;
}
