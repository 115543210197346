@use '../../styles/config/typography';
@use '../../styles/config/colors';

.wrapper {
  width: 100%;
  display: block;
}

.boldTitle,
.title {
  padding-left: 10px;
  font-style: normal;
  font-size: typography.$font-size-small;
  color: colors.$black;
}

.title {
  font-weight: typography.$font-weight-normal;
}

.boldTitle {
  font-weight: typography.$font-weight-bold;
}

.circleWithProgress {
  display: flex;
}

.greenCircle,
.whiteCircle {
  width: typography.$font-size-x-large;
  height: typography.$font-size-x-large;
  line-height: typography.$font-size-x-large;
  border-radius: 50%;
  font-size: typography.$font-size-small + 1;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
}

.whiteCircle {
  color: colors.$blue-grey;
  background: colors.$white;
  border: 1px solid colors.$blue-grey;
}

.greenCircle {
  background: colors.$green-100;
  color: colors.$white;
}

.bar {
  display: flex;
  width: 0px;
  border-radius: 0;
  border-right: 1px solid colors.$gray-50;
  height: typography.$font-size-tiny;
  justify-content: center;
  margin-left: 11px;
}
